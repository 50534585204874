import React from "react";

import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import ContactLink from "../components/contactLink";
import ImageHeading from "../components/imageHeading";

function ServicesPage({ data }) {
  return (
    <Layout>
      <ImageHeading
        background="bg-indigo"
        image={data.productionImage2.childImageSharp.gatsbyImageData}
        alt="Fans enjoying some IRL Merch"
        heading="Services"
      />

      <div className="bg-grey-dark px-8 py-20">
        <div className="max-w-4xl mx-auto">
          <h3 className="font-display font-bold text-4xl text-white mb-4">
            Key Services
          </h3>
          <p className="text-xl text-white opacity-75">
            We’ll handle every aspect of your merchandising – from creative
            branding, product design and manufacture to omni-channel sales and
            fulfilment. Boasting record-breaking sales on world tours and our
            distribution points in USA and Europe, we can truly take you global.
          </p>
        </div>
      </div>

      <div className="bg-indigo">
        <div className="mx-auto max-w-full flex flex-wrap">
          <div className="bg-indigo text-salmon md:w-1/2 lg:w-1/2 px-12 py-20 flex flex-col">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 20 20"
              className="fill-current mb-6"
            >
              <path d="M13.5 19h-8c-0.827 0-1.5-0.673-1.5-1.5v-9.306l-2.342 0.781c-0.262 0.087-0.545-0.054-0.632-0.316l-1-3c-0.079-0.236 0.028-0.494 0.251-0.605l6-3c0.155-0.077 0.339-0.069 0.486 0.022s0.237 0.252 0.237 0.425c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5c0-0.173 0.090-0.334 0.237-0.425s0.331-0.099 0.486-0.022l6 3c0.223 0.111 0.329 0.369 0.251 0.605l-1 3c-0.087 0.262-0.37 0.404-0.632 0.316l-2.342-0.781v9.306c0 0.827-0.673 1.5-1.5 1.5zM4.5 7c0.104 0 0.206 0.032 0.292 0.094 0.13 0.094 0.208 0.245 0.208 0.406v10c0 0.276 0.224 0.5 0.5 0.5h8c0.276 0 0.5-0.224 0.5-0.5v-10c0-0.161 0.077-0.312 0.208-0.406s0.298-0.12 0.45-0.069l2.526 0.842 0.705-2.114-4.973-2.487c-0.351 1.562-1.749 2.733-3.415 2.733s-3.065-1.171-3.415-2.733l-4.973 2.487 0.705 2.114 2.526-0.842c0.052-0.017 0.105-0.026 0.158-0.026z"></path>
            </svg>
            <h3 className="font-display font-bold text-xl md:text-2xl">PRODUCTION</h3>
            <p className="font-bold mt-4 lg:text-xl">
              Specialists in procurement and design.
            </p>
            <p className="mt-4 opacity-75 lg:text-xl">
              Whether building your brand from scratch or elevating it to new
              heights, we’re ready to realise your dreams. From simple clothing
              to custom couture and quirky accessories/ornaments, we will make
              it happen and with a seal of quality. Always on trend, we work
              with talented designers who know what’s hot – so if you need
              inspiration (or have ideas of your own), we will help every step
              of the way.
            </p>
          </div>

          <GatsbyImage
            className="w-full h-64 md:h-auto md:w-1/2 lg:w-1/2"
            image={data.productionImage.childImageSharp.gatsbyImageData}
            alt="Fans enjoying some IRL Merch"
          />

          <div className="bg-mint text-purple md:w-1/2 lg:w-1/2 px-12 py-20 flex flex-col">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 20 20"
              className="fill-current mb-6"
            >
              <path d="M0.5 19c-0.225 0-0.422-0.15-0.482-0.367s0.032-0.447 0.225-0.562c1.691-1.014 2.392-2.489 2.641-3.179-1.838-1.407-2.884-3.354-2.884-5.392 0-1.029 0.258-2.026 0.768-2.964 0.486-0.894 1.18-1.695 2.061-2.381 1.787-1.39 4.156-2.156 6.671-2.156s4.884 0.766 6.671 2.156c0.881 0.685 1.575 1.486 2.061 2.381 0.51 0.937 0.768 1.934 0.768 2.964s-0.258 2.026-0.768 2.964c-0.486 0.894-1.18 1.695-2.061 2.381-1.787 1.39-4.156 2.156-6.671 2.156-1.033 0-2.047-0.129-3.016-0.385-0.429 0.286-1.231 0.793-2.189 1.27-1.488 0.74-2.764 1.115-3.794 1.115zM9.5 3c-4.687 0-8.5 2.916-8.5 6.5 0 1.815 1.005 3.562 2.756 4.792 0.172 0.121 0.25 0.336 0.196 0.539-0.117 0.436-0.515 1.633-1.58 2.788 1.302-0.456 2.704-1.247 3.739-1.959 0.123-0.085 0.277-0.11 0.421-0.069 0.948 0.271 1.947 0.409 2.968 0.409 4.687 0 8.5-2.916 8.5-6.5s-3.813-6.5-8.5-6.5z"></path>
            </svg>
            <h3 className="font-display font-bold text-xl md:text-2xl">
              DIGITAL CONTENT
            </h3>
            <p className="font-bold mt-4 lg:text-xl">
              Masters of marketing and internet wizards.
            </p>
            <p className="mt-4 opacity-75 lg:text-xl">
              With founders who owe their success to the internet, we get it. We
              design and build personalised websites so you’re not sharing a
              space with thousands of other clients. We create cool content for
              social media promotion and build communities of dedicated
              customers with our powerful newsletter services. Not just making
              the merch, but helping you to make it fly off the shelves.
            </p>
          </div>

          <div className="bg-salmon text-indigo md:w-1/2 lg:w-1/2 px-12 py-20 flex flex-col">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 20 20"
              className="fill-current mb-6"
            >
              <path d="M18.5 2h-2.5v-0.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.5h-10v-0.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.5h-2.5c-0.827 0-1.5 0.673-1.5 1.5v14c0 0.827 0.673 1.5 1.5 1.5h17c0.827 0 1.5-0.673 1.5-1.5v-14c0-0.827-0.673-1.5-1.5-1.5zM1.5 3h2.5v1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-1.5h10v1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-1.5h2.5c0.276 0 0.5 0.224 0.5 0.5v2.5h-18v-2.5c0-0.276 0.224-0.5 0.5-0.5zM18.5 18h-17c-0.276 0-0.5-0.224-0.5-0.5v-10.5h18v10.5c0 0.276-0.224 0.5-0.5 0.5z"></path>
              <path d="M7.5 10h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M10.5 10h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M13.5 10h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M16.5 10h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M4.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M7.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M10.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M13.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M16.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M4.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M7.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M10.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M13.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M16.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M4.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M7.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M10.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M13.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M16.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
            </svg>
            <h3 className="font-display font-bold text-xl md:text-2xl">EVENTS</h3>
            <p className="font-bold mt-4 lg:text-xl">
              From epic conventions to boutique pop-ups.
            </p>
            <p className="mt-4 opacity-75 lg:text-xl">
              We’re not *always* behind a computer screen – we’re just as
              comfortable venturing into the real world to bring your merch
              straight to the community. Attending all the biggest events, we
              can craft huge creative retail spaces, teach you how to run your
              own branded stall and showcase your wares at IRL Merch spaces for
              cross-promotion. Whether it’s producing one-off items for special
              events, hosting meet-and-greets or throwing themed parties – the
              world can be yours.
            </p>
          </div>

          <GatsbyImage
            className="w-full h-64 md:h-auto md:w-1/2 lg:w-1/2"
            image={data.productionImage3.childImageSharp.gatsbyImageData}
            alt="Fans enjoying some IRL Merch"
          />

          <div className="bg-purple text-mint md:w-1/2 lg:w-1/2 px-12 py-20 flex flex-col">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 20 20"
              className="fill-current mb-6"
            >
              <path d="M5.5 16c-0.827 0-1.5-0.673-1.5-1.5s0.673-1.5 1.5-1.5 1.5 0.673 1.5 1.5-0.673 1.5-1.5 1.5zM5.5 14c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5 0.5-0.224 0.5-0.5-0.224-0.5-0.5-0.5z"></path>
              <path d="M14.5 16c-0.827 0-1.5-0.673-1.5-1.5s0.673-1.5 1.5-1.5 1.5 0.673 1.5 1.5-0.673 1.5-1.5 1.5zM14.5 14c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5 0.5-0.224 0.5-0.5-0.224-0.5-0.5-0.5z"></path>
              <path d="M13.5 5h-7c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h7c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
              <path d="M15.5 6h-11c-0.276 0-0.5 0.224-0.5 0.5v5c0 0.276 0.224 0.5 0.5 0.5h4.998c0.001 0 0.001 0 0.002 0s0.001-0 0.002-0h4.996c0.001 0 0.001 0 0.002 0s0.001-0 0.002-0h0.998c0.276 0 0.5-0.224 0.5-0.5v-5c0-0.276-0.224-0.5-0.5-0.5zM5 7h10v4h-0.349l-2.874-1.916c-0.23-0.153-0.54-0.091-0.693 0.139s-0.091 0.54 0.139 0.693l1.626 1.084h-3.197l-2.874-1.916c-0.23-0.153-0.54-0.091-0.693 0.139s-0.091 0.54 0.139 0.693l1.626 1.084h-2.849v-4z"></path>
              <path d="M15.616 1.783c-1.363-0.519-3.253-0.783-5.616-0.783s-4.252 0.263-5.616 0.783c-1.971 0.751-2.384 1.892-2.384 2.717v11c0 1.207 0.86 2.217 2 2.45v1.050c0 0.551 0.449 1 1 1h1c0.551 0 1-0.449 1-1v-1h6v1c0 0.551 0.449 1 1 1h1c0.551 0 1-0.449 1-1v-1.050c1.14-0.232 2-1.242 2-2.45v-11c0-0.825-0.414-1.966-2.384-2.717zM6 19h-1v-1h1v1zM14 19v-1h1v1h-1zM17 15.5c0 0.827-0.673 1.5-1.5 1.5h-11c-0.827 0-1.5-0.673-1.5-1.5v-11c0-0.743 0.586-1.343 1.741-1.783 1.232-0.469 3.050-0.717 5.259-0.717s4.028 0.248 5.259 0.717c1.155 0.44 1.741 1.040 1.741 1.783v11z"></path>
            </svg>
            <h3 className="font-display font-bold text-xl md:text-2xl">TOUR SUPPORT</h3>
            <p className="font-bold mt-4 lg:text-xl">
              Global reputation with top talent.
            </p>
            <p className="mt-4 opacity-75 lg:text-xl">
              No stranger to a crazy crowd, we’ve broken per-head sales records
              at The London Palladium and Fox Theatre Detroit, among dozens of
              others all over the planet. We also do it right with the
              logistical skills to get goods in on time, managing hundreds of
              local staff at every venue and making sure every item is in
              pristine condition ready for purchase by happy fans. Let’s go on
              the road!
            </p>
          </div>
        </div>
      </div>

      <div className="bg-grey-dark p-16 text-center">
        <p className="max-w-4xl mx-auto text-xl text-white opacity-75 pb-8">
          Want to find out more about how we've helped our clients?
        </p>
        <Link
          to="/projects"
          className="text-xl bg-white text-grey-dark mt-4 px-6 py-4 inline-block shadow hover:shadow-lg"
        >
          See Projects &rarr;
        </Link>
      </div>

      <ContactLink />
    </Layout>
  );
}

export const fluidImage = graphql`fragment fluidImage on File {
  childImageSharp {
    gatsbyImageData(quality: 85, layout: FULL_WIDTH)
  }
}
`;

export const query = graphql`query ServicesImageQuery {
  productionImage: file(relativePath: {eq: "shop-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 750
        height: 500
        formats: [PNG]
        transformOptions: {duotone: {highlight: "#fe947d", shadow: "#5d15e5"}}
        layout: CONSTRAINED
      )
    }
  }
  productionImage2: file(relativePath: {eq: "shop-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        formats: [PNG]
        transformOptions: {duotone: {highlight: "#fe947d", shadow: "#5d15e5"}}
        layout: FULL_WIDTH
      )
    }
  }
  productionImage3: file(relativePath: {eq: "shop-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 750
        height: 500
        formats: [PNG]
        transformOptions: {duotone: {highlight: "#51f8b2", shadow: "#671ea8"}}
        layout: CONSTRAINED
      )
    }
  }
}
`;

export default ServicesPage;

export function Head() {
  return (
    <SEO title="Services" />
  )
}
